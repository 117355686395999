<template>
<div class="kt-portlet">
    <div class="kt-portlet__body">
        <div>
            <div class="kt-font-lg kt-font-bolder text-center">
                Enter password to unlock session
            </div>
            <div class="form-group mt-3">
                <div class="input-group">
                    <input
                        v-model="password"
                        type="password"
                        class="form-control kt-font-bold"
                        placeholder="Password"
                    >
                    <div class="input-group-append">
                        <button
                            class="btn"
                            :class="{
                                'btn-primary': password,
                                'btn-warning': !password,
                            }"
                            type="button"
                            :disabled="saving || !password"
                            @click.stop.prevent="unLock"
                        >
                            <span class="kt-font-bolder">
                                Unlock
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import * as network from '../network';

export default {
    name: 'SoftLocked',
    data() {
        return {
            password: null,
            saving: false,
        };
    },
    computed: {
    },
    methods: {
        unLock() {
            const v = this;
            const { password } = v;
            if (v.saving || !password) return;
            v.saving = true;

            network.cipher.unLockSession({ body: { password } }, (err) => {
                v.saving = false;
                if (err) {
                    v.showError(err);
                    window.location.href = '/logout';
                } else {
                    network.session.refreshSession({}, (err, user) => {
                        if (err) return v.showError(err);
                        window.location.href = '/home';
                    });
                }
            });
        },
    },
};
</script>
