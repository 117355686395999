var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet" }, [
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", [
        _c("div", { staticClass: "kt-font-lg kt-font-bolder text-center" }, [
          _vm._v(" Enter password to unlock session "),
        ]),
        _c("div", { staticClass: "form-group mt-3" }, [
          _c("div", { staticClass: "input-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "form-control kt-font-bold",
              attrs: { type: "password", placeholder: "Password" },
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.password = $event.target.value
                },
              },
            }),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "button",
                {
                  staticClass: "btn",
                  class: {
                    "btn-primary": _vm.password,
                    "btn-warning": !_vm.password,
                  },
                  attrs: {
                    type: "button",
                    disabled: _vm.saving || !_vm.password,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.unLock.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "kt-font-bolder" }, [
                    _vm._v(" Unlock "),
                  ]),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }